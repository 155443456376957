import React, { useState, useEffect } from 'react';

import useApi from '../hooks/useApi';
import { useForm } from '../hooks/useForm';
import { TextInput, PhoneInput, Checkbox, Label, TextArea } from '../components/FormInputs';
import FormContext from '../context/FormContext';
import useModal from '../hooks/useModal';

const gdprContentUrl = `${window.baseUrl}/modal/gdpr`;

// temporary
import './style.css';

const blankData = {
    academicTitle: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    medicalId: '',
    note: '',
    personalDataProcessingConsent: false,
};

const validate = (data) => {
    const errors = {};

    if (!data.firstName) {
        errors['firstName'] = 'Chybí Vaše křestní jméno.';
    }

    if (!data.lastName) {
        errors['lastName'] = 'Chybí Vaše příjmení.';
    }

    if (!data.email) {
        errors['email'] = 'Chybí Váš e-mail.';
    }

    if (!data.personalDataProcessingConsent) {
        errors['personalDataProcessingConsent'] = true;
    }

    return errors;
};

const EduEventAttendance = ({ eduEventId }) => {
    const [sending, setSending] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [failed, setFailed] = useState(false);
    const form = useForm(blankData, validate);
    const { api } = useApi();
    const { openModal, Modal: GdprModal } = useModal();

    useEffect(() => {
        form.handleSubmit(onSubmit);
    }, []);

    const onSubmit = async (values) => {
        setSending(true);
        delete values['personalDataProcessingConsent'];

        const response = await api.post('/edu-events/:eduEventId/attendees', { path: { eduEventId }, formData: values });

        if (response.code === 500) {
            setFailed('Ou, něco se pokazilo. Zkuste to prosím znovu.');
            setSending(false);
            return;
        }

        const payload = await response.json();

        if (payload?.statusCode !== 201) {
            if (payload.error?.invalidParameter) {
                form.setError(payload.error.invalidParameter, payload.error.description || true);
            } else {
                setFailed('Některá položka není správně vyplněná.');
            }
            setSending(false);
            return;
        }

        form.resetForm();
        setSucceeded(true);
    };

    if (succeeded) {
        return (
            <div className="attendance-succeeded bg-lightest-blue">
                <h2>Odesláno, děkujeme.</h2>
            </div>
        );
    }

    return <>
        <GdprModal contentUrl={gdprContentUrl}></GdprModal>

        <FormContext.Provider value={form}>
            {failed && <div className="form__error">
                {failed}
            </div>}
            <form className="form">

                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <TextInput name="academicTitle" label="Titul" placeholder="Např. MUDr." />
                    </div>
                    <div className="form__group">
                        <TextInput name="firstName" label="Křestní jméno" placeholder="Např. Jan/Jana" />
                    </div>
                    <div className="form__group">
                        <TextInput name="lastName" label="Příjmení" placeholder="Např. Novák/Nováková" />
                    </div>
                </div>

                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <TextInput type="email" name="email" label="Váš e-mail" placeholder="Např. jan.novak@seznam.cz" />
                    </div>
                    <div className="form__group">
                        <PhoneInput name="phone" label="Telefon" />
                    </div>
                </div>

                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <Label><a href="https://www.lkcr.cz/seznam-lekaru" target="_blank">ID lékaře</a></Label>
                        <TextInput name="medicalId" noLabel />
                    </div>
                </div>

                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <TextArea name="note" label="Poznámka" />
                    </div>
                </div>

                <div className="form__control">
                    <div className="form__group">
                        <Checkbox name="personalDataProcessingConsent"
                            label={<>Souhlasím se <a href="#" onClick={openModal}>zpracováním osobních údajů</a></>} />
                    </div>
                </div>

                <div className="form__control">
                    <div className="form__group">
                        Registrací souhlasím, že mi může KKN posílat e-maily o dalších vzdělávacích akcích.
                    </div>
                </div>

                <div className="form__control form__control--button">
                    <div className="form__group">
                        <button onClick={form.handleSubmit(onSubmit)} className={`button button--dark-blue ${sending ? 'button--disabled' : ''}`} disabled={sending}>
                            {sending ? 'Čekejte, odesílá se...' : 'Registrovat'}
                        </button>
                    </div>
                </div>
            </form>
        </FormContext.Provider>
    </>;
};

export default EduEventAttendance;
