import { throwError } from '../utils/utils.js';

const apiBaseUrl = () => {
    const baseUrl = window?.baseUrl;
    if (!baseUrl || !baseUrl.match(/^http/)) {
        throwError('Undefined window.baseUrl.');
    }
    return baseUrl.replace(/\/$/, '') + '/api';
}

const resolveUrl = (endpoint, baseUrl, params = {}) => {
    const { path, query } = { ...{ path: null, query: null }, ...params };
    const uri = resolvePathParams(endpoint, path);
    let url = absUrl(baseUrl, uri);
    if (query) {
        const queryString = new URLSearchParams(filterNullProps(query)).toString();
        url = url.replace(/\?.*/, '') + (queryString ? `?${queryString}` : '');
    }
    return url;
};

const prepareFormData = (data) => {
    const formData = new FormData;
    Object.keys(data).forEach(key => {
        formData.append(key, data[key])
    });
    return formData;
}

const resolvePathParams = (url, params) => {
    params ||= {};
    const paramRegexp = (name, modifiers) => new RegExp(`:${name}(\\?)?(?=[^a-z]|$)`, modifiers || 'i');
    const pattern = url;
    Object.keys(params).forEach(param => {
        const regexp = paramRegexp(param);
        const value = params[param] || '';
        const match = url.match(regexp);
        const optional = match && match[1] === '?';
        if (!match) {
            throwError(`Missing parameter '${param}' in URL pattern '${pattern}'.`);
        }
        if (!optional && !value) {
            throwError(`Undefined value of parameter '${param}'.`);
        }
        url = url.replace(regexp, value);
    })
    url = url.replace(/\/{2,}/, '/');
    const unresolved = [...url.matchAll(paramRegexp('[a-z]+', 'ig'))].map(([param]) => param);
    if (unresolved.length > 0) {
        throwError(`Unresolved URL parameters: '${unresolved.join("', '")}'.`);
    }
    return url;
}

const absUrl = (baseUrl, endpoint) => `${baseUrl.replace(/\/$/, '')}/${endpoint.replace(/^\//, '')}`;

const filterNullProps = (object) => Object.fromEntries(Object.entries(object).filter(([key, value]) => value !== null));

export { apiBaseUrl, resolveUrl, prepareFormData };
