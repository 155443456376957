import * as yup from 'yup';
import moment from 'moment';

const internshipInquiryModel = {
    initialData: {
        firstName: '',
        lastName: '',
        birthDay: '',
        email: '',
        phone: '',
        residenceStreet: '',
        residenceCity: '',
        residencePostalCode: '',
        schoolName: '',
        schoolAddress: '',
        schoolIdNumber: '',
        schoolTaxId: '',
        fieldOfStudy: '',
        currentYearOfStudy: '',
        location: '',
        department: '',
        position: '',
        startDate: '',
        endDate: '',
        accommodationRequired: false,
        customAccommodationDate: 'no',
        accommodationFirstDay: '',
        accommodationLastDay: '',
        notice: '',
        personalDataProcessingConsent: false,
    },
    validate: async (data) => {
        let errors = {};

        const schema = yup.object().shape({
            firstName: yup.string()
                .required('Chybí Vaše křestní jméno.')
                .max(32, 'Křestní jméno je příliš dlouhé.'),

            lastName: yup.string()
                .required('Chybí Vaše příjmení.')
                .max(32, 'Příjmení je příliš dlouhé.'),

            birthDay: yup.string()
                .test({
                    name: 'valid-birthday',
                    test(value, ctx) {
                        if (!value) {
                            return ctx.createError({ message: 'Chybí Vaše datum narození.' });
                        }
                        const birthdate = moment(value, 'YYYY-MM-DD', true);
                        if (!birthdate.isValid()) {
                            return ctx.createError({ message: 'Datum narození musí být ve formátu RRRR-MM-DD, např. 2000-07-01.' });
                        }
                        const age = moment().diff(birthdate, 'years');
                        if (age > 50) {
                            return ctx.createError({ message: `Opravdu chcete v ${age} letech na praxi?` });
                        }
                        if (age < 15) {
                            return ctx.createError({ message: `Ve vašem věku k nám bohužel nemůžete na praxi.` });
                        }
                        return true;
                    }
                }),

            email: yup.string()
                .required('Chybí Váš e-mail.')
                .max(64, 'E-mail je příliš dlouhý.')
                .email('E-mail není platný.'),

            residenceStreet: yup.string()
                .required('Chybí ulice a č.p. Vašeho bydliště.')
                .max(32, 'Adresa bydliště je příliš dlouhá.'),

            residenceCity: yup.string()
                .required('Chybí město, ve kterém bydlíte.')
                .max(32, 'Město je příliš dlouhé.'),

            residencePostalCode: yup.string()
                .required('Chybí PSČ Vašeho bydliště.')
                .matches(/[0-9]{5}/, 'PSČ musí mít 5 číslic.'),

            schoolName: yup.string()
                .required('Chybí název Vaší školy.')
                .max(64, 'Název školy je příliš dlouhý.'),

            schoolAddress: yup.string()
                .required('Chybí adresa Vaší školy.')
                .max(64, 'Adresa školy je příliš dlouhá.'),

            schoolIdNumber: yup.string()
                .max(16, 'IČ školy je příliš dlouhé.'),

            schoolTaxId: yup.string()
                .max(16, 'DIČ školy je příliš dlouhé.'),

            fieldOfStudy: yup.string()
                .required('Chybí obor, který studujete.')
                .max(32, 'Obor je příliš dlouhý.'),

            location: yup.string()
                .required('Vyberte nemocnici, ve které chcete absolvovat praxi.'),

            department: yup.string()
                .required('Vyberte oddělení, ve které chcete absolvovat praxi.'),

            position: yup.string()
                .required('Vyberte pozici, pro kterou se budete na praxi připravovat.'),

            startDate: yup.date()
                .nullable().transform((curr, orig) => orig === '' ? null : curr)
                .typeError('Neplatný den nástupu na praxi.')
                .required('Chybí den, kdy chcete nastoupit na praxi.'),

            endDate: yup.date()
                .nullable().transform((curr, orig) => orig === '' ? null : curr)
                .typeError('Neplatný den ukončení praxe.')
                .required('Chybí den, kdy chcete ukončit praxi.'),

            notice: yup.string()
                .max(2048, 'Poznámka je příliš dlouhá.'),

            accommodationRequired: yup.boolean(),
            customAccommodationDate: yup.string(),

            accommodationFirstDay: yup.date()
                .nullable().transform((curr, orig) => orig === '' ? null : curr)
                .typeError('První den ubytování není platné datum.')
                .when(['accommodationRequired', 'customAccommodationDate'], {
                    is: (required, custom) => required && custom === 'yes',
                    then: schema => schema.required('Chybí první den ubytování.'),
                    otherwise: schema => schema
                }),

            accommodationLastDay: yup.date()
                .nullable().transform((curr, orig) => orig === '' ? null : curr)
                .typeError('Poslední den ubytování není platné datum.')
                .when(['accommodationRequired', 'customAccommodationDate'], {
                    is: (required, custom) => required && custom === 'yes',
                    then: schema => schema.required('Chybí poslední den ubytování.'),
                    otherwise: schema => schema
                }),
        });

        try {
            await schema.validate(data, { abortEarly: false });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                errors = err.inner.reduce((acc, currentError) => {
                    acc[currentError.path] = currentError.message;
                    return acc;
                }, {});
            } else {
                throw err;
            }
        }

        if (!data.personalDataProcessingConsent) {
            errors['personalDataProcessingConsent'] = true;
        }

        return errors;
    }
};

export default internshipInquiryModel;
