import React, { useCallback } from 'react';
import PhoneNumberInput from './PhoneNumberInput';

import { useFormContext } from '../hooks/useForm';

export const Label = ({ children, className = 'form__label' }) => {
    return children && <label className={className}>{children}</label>;
};

export const InvalidFeedback = ({ children }) => {
    return children && <span className="form__error">{children}</span>
};

/**
 * <TextInput name="name" label="Your name" />
 */
export const TextInput = ({ name, label, placeholder = null, noLabel = false, noFeedback = false, type = "text" }) => {
    const { register, errors } = useFormContext();

    return (<>
        {!noLabel && <Label>{label}</Label>}
        <input type={type} {...register(name)}
            className={`form__input form__input--text ${errors[name] ? 'form__input--invalid' : ''}`} placeholder={placeholder} />
        {!noFeedback && <InvalidFeedback>{errors[name]}</InvalidFeedback>}
    </>);
};

/**
 * <TextArea name="name" label="Your name" />
 */
export const TextArea = ({ name, label, placeholder = null, noLabel = false, noFeedback = false }) => {
    const { register, errors } = useFormContext();

    return (<>
        {!noLabel && <Label>{label}</Label>}
        <textarea {...register(name)}
            className={`form__textarea ${errors[name] ? 'form__input--invalid' : ''}`} placeholder={placeholder}></textarea>
        {!noFeedback && <InvalidFeedback>{errors[name]}</InvalidFeedback>}
    </>);
};
/**
 * <Select name="gender" label="Your gender" mapper="slug=title" />
 */
export const Select = ({ name, label, mapper, children, onChange = null, noLabel = false, noFeedback = false, emptyOption = true }) => {
    const { register, options, errors } = useFormContext();

    if (typeof mapper === 'string') {
        const pair = mapper.split('=');
        if (pair.length !== 2) {
            throw '<Select />: Property `mapper` must be like "valueKey=textKey". '
            + 'Source would be mapped into <option value={item[valueKey]}>{item[textKey]</option>.';
        }
        mapper = (item) => [item[pair[0]], item[pair[1]]];
    }

    return (<>
        {!noLabel && <Label>{label}</Label>}
        <select {...register(name, { onChange })} className={`form__input ${errors[name] ? 'form__input--invalid' : ''}`}>
            {emptyOption && <option value="">(vyberte)</option>}
            {children
                ? children
                : options(name).map((option) => {
                    const [value, text] = mapper(option);
                    return <option key={value} value={value}>{text}</option>
                })}
        </select>
        {!noFeedback && <InvalidFeedback>{errors[name]}</InvalidFeedback>}
    </>);
};

/**
 * <Checkbox name="subscribe" label="Subscribe" />
 */
export const Checkbox = ({ name, label }) => {
    const { register, errors } = useFormContext();

    return (
        <Label className={`form__checkbox ${errors[name] ? 'form__input--invalid' : ''}`}>
            {label}
            <input {...register(name)} type="checkbox" />
            <span className="checkmark"></span>
        </Label>
    );
};

/**
 * <Radio name="subscribe" label="Subscribe" />
 */
export const Radio = ({ name, value, label }) => {
    const { register, errors } = useFormContext();

    return (
        <Label className={`form__checkbox ${errors[name] ? 'form__input--invalid' : ''}`}>
            {label}
            <input {...register(name, { value })} type="radio" />
            <span className="checkmark"></span>
        </Label>
    );
};

/**
 * <PhoneInput name="phone" label="Phone number" />
 */
export const PhoneInput = ({ name, label, placeholder = null, noLabel = false, noFeedback = false }) => {
    const { values, errors, setValue, removeError } = useFormContext();

    const onChange = useCallback((phone) => {
        removeError(name);
        setValue(name, phone || '');
    }, [])

    return (<>
        {!noLabel && <Label>{label}</Label>}
        <PhoneNumberInput value={values[name]} onChange={onChange}
            className={`form__input form__input--text ${errors[name] ? 'form__input--invalid' : ''}`} placeholder={placeholder} />
        {!noFeedback && <InvalidFeedback>{errors[name]}</InvalidFeedback>}
    </>);
};
