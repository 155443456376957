import React from 'react';
const baseUrl = `${window.baseUrl}`

const JobOfferItem = ({ data, link }) => {
    const { id, title, department, location, position, description, activeFrom, activeTo, top, created } = data;
    return <div className="jobOffer-list__item">
        <div className="jobOffer-list__image">
            <img className="svg" src={baseUrl + '/assets/images/ui/icons/departments/detske-oddeleni.svg'} alt="" />
        </div>
        <div className="jobOffer-list__row">
            <h4 className="jobOffer-list__name">
                {department.type === 'other' ? title : department.name}
            </h4>
            <div className="jobOffer-list__details">
                <div className="jobOffer-list__info">
                    {top && <strong className="jobOffer-list__topOffer">TOP</strong>}
                    {department.type !== 'other' && <span className="jobOffer-list__position">{title}</span>}
                    <span className="jobOffer-list__city">{location.name}</span>
                </div>
                <a className="jobOffer-list__link" href={link}>Mám zájem &gt;</a>
            </div>
        </div>
    </div>;
};

export default JobOfferItem;
