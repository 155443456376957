import { throwError } from '../utils/utils.js';
import { apiBaseUrl, resolveUrl, prepareFormData } from '../utils/apiHelpers.js';

const setup = {
    apiBaseUrl: apiBaseUrl(),
    onUnauthenticated: () => { },
    onForbidden: () => { },
    onUnavailable: () => { },
}

const useApi = () => {
    const createRequest = (endpoint, data = {}, options = {}) => {
        const { path, query, jsonData, formData } = { ...{ path: null, query: null, jsonData: null, formData: null }, ...data };
        const url = resolveUrl(endpoint, setup.apiBaseUrl, { path, query });
        let body = null;

        if (formData) {
            body = prepareFormData(formData);
        } else if (jsonData) {
            body = prepareJsonBody(jsonData);
        }

        return new Request(url, {
            ...{ ...options, body },
            headers: options.headers,
        });
    };

    const apiCall = async (endpoint, data = {}, options = {}) => {
        try {
            const request = createRequest(endpoint, data, options);
            const response = await fetch(request);

            if (response.status === 403) {
                setup.onForbidden();
            }

            if (response.status === 503) {
                setup.onUnavailable('Služba je momentálně nedostupná z důvodu údržby. Zkuste to, prosím, za chvilku.');
            }

            if (response.status === 401) {
                if (response?.error?.type === 'INVALID_CREDENTIALS') {
                    // do not call the callback, the error is handled explicitly
                    return response;
                }

                setup.onUnauthenticated('Vaše přihlášení vypršelo.');
            }

            return response;

        } catch (error) {
            apiError(error);
        }
    };

    const apiGet = async (endpoint, data = {}, options = {}) => apiCall(endpoint, data, { ...options, method: 'GET' });

    const apiPost = async (endpoint, data = {}, options = {}) => apiCall(endpoint, data, { ...options, method: 'POST' });

    const apiError = (error) => throwError({ prefix: 'API call failed', error });

    return {
        onUnauthenticated: (callback) => {
            setup.onUnauthenticated = callback;
        },

        onForbidden: (callback) => {
            setup.onForbidden = callback;
        },

        onUnavailable: (callback) => {
            setup.onUnavailable = callback;
        },

        api: {
            request: createRequest,
            call: apiCall,
            get: apiGet,
            post: apiPost,
        },
    }
}

export default useApi;
