import React, { useState, useEffect, useCallback } from 'react';
import JobOfferItem from './JobOfferItem.jsx';
import useApi from '../hooks/useApi.js';
import { scrollTo, plural } from '../utils/utils.js';

const urlHashPrefix = '#';

const restoreFiltersFromUrlHash = () => {
    const hashParams = new URLSearchParams(window.location.hash.slice(urlHashPrefix.length));

    if (hashParams.size > 0) {
        scrollTo('.content');
    }

    return {
        departments: hashParams.get('dep') ? new Set(hashParams.get('dep').split('.')) : new Set(['all']),
        position: hashParams.get('pos') || '',
        location: hashParams.get('loc') || '',
        havingSelectionProcessOnly: hashParams.get('spo') === '1',
    }
};

const storeFiltersIntoUrlHash = ({ departments = [], position = null, location = null, havingSelectionProcessOnly = null }) => {
    const params = {};
    if (departments && departments.length > 0) {
        params['dep'] = departments.join('.');
    }
    if (position) {
        params['pos'] = position;
    }
    if (location) {
        params['loc'] = location;
    }
    if (havingSelectionProcessOnly) {
        params['spo'] = '1';
    }
    if (Object.keys(params).length === 0) {
        history.replaceState(null, null, ' ' + window.location.href.split('#')[0]);
        return;
    }
    window.location.hash = `${urlHashPrefix}${(new URLSearchParams(params)).toString()}`;
};

const JobOffers = ({ detailUrlPattern }) => {
    const [params, setParams] = useState({
        departments: [],
        positions: [],
        locations: [],
    });
    const [filters, setFilters] = useState(null);
    const [jobOffers, setJobOffers] = useState([]);
    const [jobOfferCounts, setJobOfferCounts] = useState({ tease: null, more: null });
    const { api } = useApi();

    useEffect(() => {
        api.get('job-offers/params')
            .then(response => response.json())
            .then(payload => {
                setParams({
                    departments: payload.data.departments,
                    positions: payload.data.positions,
                    locations: payload.data.locations,
                });
            })
            .catch(error => console.error('Error fetching job offer parameters:', error));
    }, []);

    useEffect(() => {
        setFilters(restoreFiltersFromUrlHash());
    }, [params]);

    useEffect(() => {
        if (filters === null) {
            return; // wait until restoreFiltersFromUrlHash()
        }

        fetchJobOffers(10);
    }, [filters]);

    const fetchJobOffers = useCallback(limit => {
        let departments = Array.from(filters.departments);
        if (departments.length === 0 || departments.includes('all')) {
            departments = null;
        }

        storeFiltersIntoUrlHash({ ...filters, departments });

        api.get('job-offers', {
            query: {
                departmentIds: departments && departments.join(','),
                position: filters.position || null,
                location: filters.location || null,
                havingSelectionProcessOnly: filters.havingSelectionProcessOnly || null,
                limit: limit || 500,
                topFirst: true,
            }
        })
            .then(response => response.json())
            .then(payload => {
                const { itemsPerPage, itemsTotal } = payload.pagination;
                const more = itemsTotal <= itemsPerPage ? 0 : (itemsTotal - itemsPerPage);
                setJobOfferCounts({ tease: itemsPerPage, more });
                setJobOffers(payload.data)
            })
            .catch(error => console.error('Error fetching job offers:', error));
    });

    const loadMore = (event) => {
        event.preventDefault();
        fetchJobOffers();
    };

    const jobOfferDetailLink = (uuid) => detailUrlPattern.replace(/__id$/, uuid);

    const handleDepartmentChange = (event) => {
        const departmentId = event.target.name;
        const checked = event.target.checked;

        if (departmentId === 'all' && filters.departments.has('all')) {
            return;
        }

        setFilters((prev) => {
            let departments = prev.departments;
            if (departmentId === 'all') {
                departments = new Set(['all']);
            } else {
                const allChecked = departments.has('all');
                if (allChecked) {
                    departments = new Set();
                }
                if (checked || allChecked) {
                    if (allChecked || !departments.has(departmentId)) {
                        departments.add(departmentId);
                    }
                } else {
                    departments.delete(departmentId);
                }
                if (departments.size === 0) {
                    departments.add('all');
                }
            }
            return { ...prev, departments };
        });
    };

    const handlePositionChange = (event) => {
        setFilters((prev) => ({ ...prev, position: event.target.value }));
    };

    const handleLocationChange = (event) => {
        setFilters((prev) => {
            let location = event.target.name;
            if (location === filters.location && !event.target.checked) {
                location = null;
            }
            return { ...prev, location };
        });
    };

    const handleSelectionProcessOnly = (event) => {
        setFilters((prev) => ({ ...prev, havingSelectionProcessOnly: event.target.checked }));
    };

    return (filters !== null &&
        <div className="jobOffer jobOffer--full form">
            <div className="jobOffer__category">
                <h3>Oddělení</h3>
                <ul className="jobOffer__departments">
                    <li className="jobOffer__departmentsItem">
                        <label className="form__checkbox">
                            Všechna oddělení
                            <input type="checkbox" id="all" name="all"
                                checked={filters.departments.has('all')} onChange={handleDepartmentChange} />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                    {params.departments.map(({ id, name, locations }) => (
                        (!filters.location || locations.includes(filters.location)) &&
                        <li key={id} className="jobOffer__departmentsItem">
                            <label htmlFor={`dept-${id}`} className="form__checkbox" style={{ opacity: filters.departments.has('all') ? '.5' : '1' }}>
                                {name}
                                <input type="checkbox" id={`dept-${id}`} name={id}
                                    checked={filters.departments.has(id + '') || filters.departments.has('all')}
                                    onChange={handleDepartmentChange} />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="jobOffer__list">
                <div className="jobOffer__filter">
                    <div className="jobOffer__position">
                        <h3>Pozice</h3>
                        <select name="position" value={filters.position} onChange={handlePositionChange} className="form__input">
                            <option value="">(všechny)</option>
                            {params.positions.map(({ slug, name }) => (
                                <option key={slug} value={slug}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="jobOffer__city">
                        <h3>Nemocnice</h3>

                        <div className="jobOffer__row">
                            {params.locations.map(({ slug, name }) => (
                                <label key={slug} htmlFor={`loc-${slug}`} className="form__checkbox">
                                    {name}
                                    <input type="checkbox" id={`loc-${slug}`} name={slug}
                                        checked={filters.location === slug}
                                        onChange={handleLocationChange} />
                                    <span className="checkmark"></span>
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="jobOffer__selection-process-only">
                        <h3>&nbsp;</h3>

                        <div className="jobOffer__row">
                            <label htmlFor="selection-process-only" className="form__checkbox">
                                pouze výběrová řízení
                                <input type="checkbox" id="selection-process-only" name="selection-process-only"
                                    checked={filters.havingSelectionProcessOnly}
                                    onChange={handleSelectionProcessOnly} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="jobOffer-list">
                    {jobOffers.length === 0 && <>
                        <div className="jobOffer-list__notification">
                            Vybranému filtru neodpovídají žádné nabídky.
                        </div>
                    </>}

                    {jobOffers.map(offer => (
                        <JobOfferItem data={offer} link={jobOfferDetailLink(offer.id)} key={offer.id} />
                    ))}

                    {jobOfferCounts.more > 0 && <p>
                        <a onClick={loadMore} className="jobOffer-list__link" href="#">
                            Načíst {plural(jobOfferCounts.more, ['další pozici', 'další %d pozice', 'dalších %d pozic'], (count, text) => text.replace(/%d/, count))}
                        </a>
                    </p>}
                </div>
            </div>
        </div>
    );
};

export default JobOffers;
