export const normalizeNewLines = (text) => text.replace(/\r\n/g, '\n');

export const scrollTo = (selector) => {
    const anchor = document.querySelector(selector);
    if (!anchor) {
        console.error(`Cannot scroll to '${selector}', element not found in DOM.`);
        return;
    }
    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const throwError = (spec) => {
    let { error, prefix } = (typeof spec === 'string' ? { error: spec, prefix: null } : spec);
    prefix = prefix ? [`${prefix.replace(/\s*:$/, '')}:`] : [];
    console.error(...[...prefix, error]);
    throw new Error(error);
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 B';

    const k = 1024;
    const sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    i < 2 && (dm = 0); // no decimals for kB and B

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const plural = (count, [singular, few, many], formatter = null) => {
    let key;
    if (count === 1) {
        key = 0;
    } else if (count >= 2 && count <= 4) {
        key = 1;
    } else {
        key = many === undefined ? 1 : 2;
    }
    const text = [singular, few, many][key];
    formatter ||= (c, t) => `${c} ${t}`;
    return formatter(count, text);
};

export const joinWords = (words = [], punctuation = ', ', conjuction = ' a ') => {
    return words.slice(0, -1).join(punctuation) + (words.length > 1 ? conjuction : '') + words.slice(-1)
};

export const arrayRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
