import React from 'react';
import { createRoot } from 'react-dom/client';
import JobOffers from './JobOffers/JobOffers';
import JobOfferFeedback from './JobOffers/JobOfferFeedback';
import EduEventAttendance from './EduEvents/EduEventAttendance';
import InternshipInquiry from './Internship/InternshipInquiry';

const map = {
    jobOffers: document.getElementById('job-offers'),
    jobOfferFeedbacks: document.querySelectorAll('[data-component="job-offer-feedback-form"]'),
    eduEventAttendance: document.querySelector('[data-component="edu-event-attendance-form"]'),
    internshipInquiry: document.querySelector('[data-component="internship-inquiry-form"]'),
}

if (map.jobOffers) {
    const jobOfferDetailUrl = map.jobOffers.getAttribute('data-job-offer-detail-url');
    createRoot(map.jobOffers).render(<JobOffers detailUrlPattern={jobOfferDetailUrl} />);
}

if (map.jobOfferFeedbacks.length > 0) {
    map.jobOfferFeedbacks.forEach((rootEl) => {
        const jobOfferId = rootEl.getAttribute('data-job-offer-id');
        createRoot(rootEl).render(<JobOfferFeedback jobOfferId={jobOfferId} />);
    });
}

if (map.eduEventAttendance) {
    const eduEventId = map.eduEventAttendance.getAttribute('data-edu-event-id');
    createRoot(map.eduEventAttendance).render(<EduEventAttendance eduEventId={eduEventId} />);
}

if (map.internshipInquiry) {
    createRoot(map.internshipInquiry).render(<InternshipInquiry />);
}
