import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';
const apiBaseUrl = `${window.baseUrl}/api`

const buildSessionStorageKey = (key) => `uploadedFiles-${key}`;

const fileMaxSizeInMB = 16;

const fileAllowedTypes = {
    'application/pdf': 'PDF',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'image/jpeg': 'JPG',
    'image/png': 'PNG',
};

const UploadAttachmentsComponent = forwardRef(({ sessionId, onFilesChange, onError }, ref) => {
    const sessionStorageKey = buildSessionStorageKey(sessionId);
    const [files, setFiles] = useState(() => {
        const savedFiles = sessionStorage.getItem(sessionStorageKey);
        return savedFiles ? JSON.parse(savedFiles) : [];
    });

    useImperativeHandle(ref, () => {
        return {
            flushUploads() {
                sessionStorage.removeItem(sessionStorageKey);
                setFiles([]);
            }
        };
    }, []);

    useEffect(() => {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(files));
        onFilesChange(files);
    }, [files]);

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];

        if (file.size > (fileMaxSizeInMB * (1024 ** 2))) {
            onError(`Soubor "${file.name}" je příliš velký. Max. velikost je ${fileMaxSizeInMB} MB.`);
            return;
        }

        if (fileAllowedTypes[file.type] === undefined) {
            const allowedFileTypes = Object.values(fileAllowedTypes).join(', ');
            onError(`Formát souboru "${file.name}" není podporovaný. Nahrajte soubor v těchto formátech: ${allowedFileTypes}.`);
            return;
        }

        const formData = new FormData;
        formData.append('file', file);

        fetch(`${apiBaseUrl}/attachments`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(payload => {
                if (payload.data) {
                    setFiles(prevFiles => [...prevFiles, payload.data]);
                }
            })
            .catch(error => {
                console.error('Chyba při nahrávání souboru:', error);
            });
    }, []);

    const handleDelete = (id) => {
        fetch(`${apiBaseUrl}/attachments/${id}`, {
            method: 'DELETE',
        })
            .then(() => {
                setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
            })
            .catch(error => {
                console.error('Chyba při mazání souboru:', error);
            });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div className="attachement-uploader">
            {/* <div className="attachement-uploader__dropzone">
                <img className="attachement-uploader__icon" src="{$basePath}/assets/images/ui/upload.svg" alt="" />
                    <p className="attachement-uploader__note">Přetáhněte nebo nahrajte soubor ze svého zařízení.</p>
            </div> */}
            <div className="attachement-uploader__files">
                {files.map(file => (
                    <div key={file.id} className="attachement-uploader__fileItem">
                        <div className="attachement-uploader__fileIcon">
                            {/^image\//.test(file.mime)
                                ? <img className="attachement-uploader__fileThumb" src={file.url} width={125} alt="" />
                                : <img className="attachement-uploader__fileThumb attachement-uploader__fileThumb--pdf" src="/assets/images/ui/icons/icon-pdf.svg" alt="" />
                            }
                            {/* <img className="attachement-uploader__fileThumb attachement-uploader__fileThumb--pdf" src={file.url} alt="" /> */}
                        </div>
                        <span className="attachement-uploader__fileName">{file.originalFilename} ({file.fileSize})</span>
                        <button type='button' onClick={() => handleDelete(file.id)}>Smazat</button>
                    </div>
                    // <li key={file.id}>
                    //     <img src={file.url} width={100} />
                    //     {file.originalFilename}
                    //     <button type='button' onClick={() => handleDelete(file.id)}>Smazat</button>
                    // </li>
                ))}

                {/* <div className="attachement-uploader__fileItem">
                    <div className="attachement-uploader__fileIcon">
                        <img className="attachement-uploader__fileThumb attachement-uploader__fileThumb--pdf" src="{$basePath}/assets/images/ui/pdf.svg" alt="" />
                    </div>
                    <span className="attachement-uploader__fileName">nahrane-cv.pdf (0,8 MB)</span>
                </div> */}
            </div>
            <div {...getRootProps()} className="attachement-uploader__dropzone">
                <input {...getInputProps()} />
                <p className="attachement-uploader__note">Přetáhněte nebo nahrajte soubor ze svého zařízení.</p>
            </div>
        </div>
    );

    // return (
    //     <div>
    //         <div {...getRootProps()} style={{ border: '1px solid black', padding: '20px', cursor: 'pointer' }}>
    //             <input {...getInputProps()} />
    //             <p>Přetáhněte soubory sem nebo klikněte pro výběr souborů</p>
    //         </div>
    //         <ul>
    //             {files.map(file => (
    //                 <li key={file.id}>
    //                     <img src={file.url} width={100} />
    //                     {file.originalFilename}
    //                     <button type='button' onClick={() => handleDelete(file.id)}>Smazat</button>
    //                 </li>
    //             ))}
    //         </ul>
    //     </div>
    // );
}, []);

export default UploadAttachmentsComponent;
