import React, { useState, useCallback, useRef, useEffect } from 'react';

import UploadAttachmentsComponent from '../components/UploadAttachmentsComponent.jsx';
import useApi from '../hooks/useApi.js';
import { useForm } from '../hooks/useForm.js';
import { TextInput, PhoneInput, TextArea, Checkbox, Label, InvalidFeedback } from '../components/FormInputs.jsx';
import FormContext from '../context/FormContext.jsx';
import useModal from '../hooks/useModal.js';

const apiBaseUrl = `${window.baseUrl}/api`;
const gdprContentUrl = `${window.baseUrl}/modal/gdpr`;

// temporary
import './style.css';

const blankData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    feedbackContent: '',
    acceptOtherJobOffers: false,
    personalDataProcessingConsent: false,
    attachments: ''
};

const validate = (data) => {
    const errors = {};

    if (!data.firstName) {
        errors['firstName'] = 'Chybí Vaše křestní jméno.';
    }

    if (!data.lastName) {
        errors['lastName'] = 'Chybí Vaše příjmení.';
    }

    if (!data.email) {
        errors['email'] = 'Chybí Váš e-mail.';
    }

    if (!data.phone) {
        errors['phone'] = 'Chybí Vaše tel. číslo.';
    }

    if (!data.feedbackContent) {
        errors['feedbackContent'] = 'Položka musí být vyplněna.';
    }

    if (!data.personalDataProcessingConsent) {
        errors['personalDataProcessingConsent'] = true;
    }

    if (!data.attachments) {
        errors['attachments'] = 'Nahrajte Vaše CV.';
    }

    return errors;
};

const JobOfferFeedback = ({ jobOfferId = null }) => {
    const uploadAttachmentsComponentRef = useRef();
    const sessionId = `job-offer-${jobOfferId || 'any'}`;
    const [sending, setSending] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [failed, setFailed] = useState(false);
    const form = useForm(blankData, validate)
    const { api } = useApi();
    const { openModal, Modal: GdprModal } = useModal();

    useEffect(() => {
        form.handleSubmit(onSubmit);
    }, []);

    const handleFilesChange = useCallback((files) => {
        form.setValue('attachments', files.map(file => file.id).join(','));
        form.removeError('attachments');
    });

    const onSubmit = async (values) => {
        setSending(true);
        delete values['personalDataProcessingConsent'];

        const response = await api.post('/job-offers/:jobOfferId?/feedbacks', { path: { jobOfferId }, formData: values });

        if (response.code === 500) {
            setFailed('Ou, něco se pokazilo. Zkuste to prosím znovu.');
            setSending(false);
            return;
        }

        const payload = await response.json();

        if (payload?.statusCode !== 201) {
            if (payload.error?.invalidParameter) {
                form.setError(payload.error.invalidParameter, payload.error.description || true);
            } else {
                setFailed('Některá položka není správně vyplněná.');
            }
            setSending(false);
            return;
        }

        form.resetForm();
        if (uploadAttachmentsComponentRef.current) {
            uploadAttachmentsComponentRef.current.flushUploads();
        }
        setSucceeded(true);
    };

    if (succeeded) {
        return (
            <div className="feedback-succeeded bg-lightest-blue">
                <h2>Odesláno, děkujeme.</h2>
            </div>
        );
    }

    return <>
        <GdprModal contentUrl={gdprContentUrl}></GdprModal>

        <FormContext.Provider value={form}>
            {failed && <div className="form__error">
                {failed}
            </div>}
            <form className="form">
                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <TextInput name="firstName" label="Křestní jméno" placeholder="Např. Jan/Jana" />
                    </div>
                    <div className="form__group">
                        <TextInput name="lastName" label="Příjmení" placeholder="Např. Novák/Nováková" />
                    </div>
                </div>

                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <TextInput type="email" name="email" label="Váš e-mail" placeholder="Např. jan.novak@seznam.cz" />
                    </div>
                    <div className="form__group">
                        <PhoneInput name="phone" label="Telefon" />
                    </div>
                </div>

                <div className="form__control form__control--1c">
                    <div className="form__group">
                        <TextArea name="feedbackContent" label={jobOfferId ? 'Vaše zpráva' : 'Popis pozice'} />
                    </div>
                </div>

                <div className="form__control">
                    <div className="form__group">
                        {jobOfferId && <Checkbox name="acceptOtherJobOffers" label="Souhlasím s nabídkou jiné pozice" />}

                        <Checkbox name="personalDataProcessingConsent"
                            label={<>Souhlasím se <a href="#" onClick={openModal}>zpracováním osobních údajů</a></>} />
                    </div>
                </div>

                <div className={`form__control ${form.errors['attachments'] ? 'form__input--invalid' : ''}`}>
                    <div className="form__group">
                        <Label>Vaše CV</Label>
                        <span className="form__note">
                            Povolené soubory pro nahrávání jsou PDF, DOCX, JPEG a PNG. <br />
                            Maximální velikost jednoho souboru je 16 MB.
                        </span>
                        <UploadAttachmentsComponent
                            ref={uploadAttachmentsComponentRef}
                            sessionId={sessionId} onFilesChange={handleFilesChange} onError={error => form.setError('attachments', error)} />
                        {form.errors['attachments'] && <InvalidFeedback>{form.errors['attachments']}</InvalidFeedback>}
                    </div>
                </div>

                <div className="form__control form__control--button">
                    <div className="form__group">
                        <button onClick={form.handleSubmit(onSubmit)} className={`button button--dark-blue ${sending ? 'button--disabled' : ''}`} disabled={sending}>
                            {sending ? 'Čekejte, odesílá se...' : 'Odeslat'}
                        </button>
                    </div>
                </div>
            </form>
        </FormContext.Provider>
    </>;
};

export default JobOfferFeedback;
