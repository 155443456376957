import React, { useState } from 'react';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState('');

    const openModal = (event) => {
        if (event) {
            event.preventDefault();
        }
        setIsOpen(true);
    };

    const closeModal = () => setIsOpen(false);

    const Modal = ({ contentUrl }) => {
        const fetchModalContent = async () => {
            if (contentUrl && isOpen) {
                try {
                    const response = await fetch(contentUrl);
                    if (response.ok !== true) {
                        setContent('Nepodařilo se načíst obsah.');
                        return;
                    }
                    const data = await response.text();
                    setContent(data);
                } catch (error) {
                    console.error('Modal content loading failed:', error);
                    setContent('Nepodařilo se načíst obsah.');
                }
            }
        };

        useState(() => {
            fetchModalContent();
        }, [isOpen, contentUrl]);

        return (
            <ResponsiveModal open={isOpen} onClose={closeModal} center>
                <div className="modal" style={{ padding: '2em' }}>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </ResponsiveModal>
        );
    };

    return {
        openModal,
        closeModal,
        Modal
    };
};

export default useModal;
