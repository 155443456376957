import { useState } from 'react';

const useQueue = () => {
    const [queue, setQueue] = useState([]);

    const enqueue = (item) => {
        setQueue(prevQueue => [...prevQueue, item]);
    };

    const dequeueAll = (callback) => {
        if (queue.length === 0) {
            return;
        }
        queue.forEach(item => callback(item));
        setQueue([]);
    };

    return { enqueue, dequeueAll, queue };
};

export default useQueue;
