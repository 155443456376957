import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style/phone-number-input.css';

const PhoneNumberInput = ({ value, onChange, placeholder, className }) => {
    return (
        <PhoneInput
            className={className}
            defaultCountry='CZ'
            placeholder={placeholder || '123 456 789'}
            value={value}
            onChange={onChange} />
    );
};

export default PhoneNumberInput;
