import { useState, useEffect, useCallback, useContext } from 'react';

import FormContext from '../context/FormContext.jsx';

import useQueue from './useQueue.js';

const useFormContext = () => useContext(FormContext);

const useForm = (initialValues, validate) => {
    const [values, setValues] = useState(initialValues || {});
    const [inputOptions, setInputOptions] = useState(null);
    const [filteredInputOptions, setFilteredInputOptions] = useState({});
    const [errors, setErrors] = useState({});
    const { enqueue, dequeueAll, queue } = useQueue();

    const register = useCallback((name, options = {}) => {
        let value, checked;
        if (options.value !== undefined) {
            value = options.value;
            checked = values[name] === value;
        } else {
            value = values[name] || '';
            checked = !!value;
        }

        let props = {
            name,
            value,
            onChange: (event) => {
                removeError(name);
                const newValue = inputValue(event.target);
                setValue(name, newValue);
                if (options.onChange) {
                    options.onChange(newValue);
                }
            },
            checked,
        };

        return props;
    }, [values]);

    const reset = useCallback((name) => {
        return {
            onClick: (event) => {
                event.preventDefault();
                setValue(name, '');
            }
        }
    }, []);

    const resetForm = () => {
        setValues(initialValues);
    };

    const options = useCallback((name) => {
        if (inputOptions === null) {
            return [];
        }
        if (filteredInputOptions[name] !== undefined) {
            return filteredInputOptions[name];
        }
        return inputOptions[name] || [];
    }, [inputOptions, filteredInputOptions]);

    const filterInputOptions = useCallback((name, filterCallback) => {
        if (inputOptions === null) {
            enqueue({ name, filterCallback });
        } else {
            setFilteredInputOptions(prev => ({
                ...prev,
                [name]: filterCallback(inputOptions[name] || [], values),
            }));
        }
    }, [inputOptions]);

    useEffect(() => {
        if (inputOptions !== null) {
            dequeueAll(({ name, filterCallback }) => {
                setFilteredInputOptions(prev => ({
                    ...prev,
                    [name]: filterCallback(inputOptions[name] || [], values),
                }));
            });
        }
    }, [inputOptions, queue]);

    const handleSubmit = (callback) => async (event) => {
        event.preventDefault();
        const validationErrors = validate ? await validate(values) : {};
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            callback(values);
        }
    };

    const setValue = (name, value) => {
        setValues((prev) => ({ ...prev, [name]: value }))
    };

    const setError = (name, error) => {
        setErrors((prev) => ({ ...prev, [name]: error }));
    };

    const removeError = (name) => {
        setErrors((prev) => {
            delete prev[name];
            return prev;
        });
    };

    return {
        register,
        reset,
        options,
        handleSubmit,
        resetForm,
        setInputOptions,
        filterInputOptions,
        setValue,
        setError,
        removeError,
        values,
        errors
    };
};

const inputValue = (el) => {
    if (!el) {
        return '';
    }
    if (el.type === 'checkbox') {
        return el.checked;
    }
    return el.value;
};

export { useForm, useFormContext };
