import React, { useState, useEffect, useCallback } from 'react';

import useApi from '../hooks/useApi.js';
import { useForm } from '../hooks/useForm.js';
import { TextInput, PhoneInput, Checkbox, Radio, Select, TextArea } from '../components/FormInputs.jsx';
import { arrayRange } from '../utils/utils.js';
import FormContext from '../context/FormContext.jsx';
import useModal from '../hooks/useModal.js';

import internshipInquiryModel from '../models/internshipInquiryModel.js';

const gdprContentUrl = `${window.baseUrl}/modal/internship-gdpr`;

// temporary
import './style.css';

const InternshipInquiry = () => {
    const [sending, setSending] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [failed, setFailed] = useState(false);
    const [internshipParams, setInternshipParams] = useState();
    const form = useForm(internshipInquiryModel.initialData, internshipInquiryModel.validate)
    const { api } = useApi();
    const { openModal, Modal: GdprModal } = useModal();

    useEffect(() => {
        form.handleSubmit(onSubmit);

        api.get('internship-inquiries/params')
            .then(response => response.json())
            .then(payload => {
                setInternshipParams({
                    department: payload.data.departments,
                    location: payload.data.locations,
                    position: payload.data.positions,
                });
            })
            .catch(error => console.error('Error fetching internship parameters:', error));
    }, []);

    useEffect(() => {
        if (internshipParams) {
            form.setInputOptions(internshipParams);
        }
    }, [internshipParams]);

    const onSubmit = async (values) => {
        setSending(true);
        delete values['personalDataProcessingConsent'];

        const response = await api.post('/internship-inquiries', { formData: values });

        if (response.code === 500) {
            setFailed('Ou, něco se pokazilo. Zkuste to prosím znovu.');
            setSending(false);
            return;
        }

        const payload = await response.json();

        if (payload?.statusCode !== 201) {
            if (payload.error?.invalidParameter) {
                form.setError(payload.error.invalidParameter, payload.error.description || true);
            } else {
                setFailed('Některá položka není správně vyplněná.');
            }
            setSending(false);
            return;
        }

        form.resetForm();
        setSucceeded(true);
    };

    const filterDepartments = useCallback((location) => {
        form.filterInputOptions('department', (departments, formValues) => {
            if (!location) {
                return departments;
            }
            const filteredDepartments = departments.filter(department => department.locations.includes(location));

            const selectedDepartment = parseInt(formValues.department || '0');
            const selectedExists = filteredDepartments.some(department => department.id === selectedDepartment);
            if (selectedDepartment && !selectedExists) {
                form.setValue('department', '');
            }
            return filteredDepartments;
        });
    }, []);

    const onLocationChange = location => filterDepartments(location);

    if (succeeded) {
        return (
            <div className="inquiry-succeeded bg-lightest-blue">
                <h2>Odesláno, děkujeme.</h2>
            </div>
        );
    }

    return <>
        <GdprModal contentUrl={gdprContentUrl}></GdprModal>

        <FormContext.Provider value={form}>
            {failed && <div className="form__error">
                {failed}
            </div>}
            <form className="form form--extended">

                <h3 className="form__sectionHeadline">Základní údaje</h3>
                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <TextInput name="firstName" label="Křestní jméno" placeholder="Např. Jan/Jana" />
                    </div>
                    <div className="form__group">
                        <TextInput name="lastName" label="Příjmení" placeholder="Např. Novák/Nováková" />
                    </div>
                    <div className="form__group">
                        <TextInput name="birthDay" label="Datum narození" placeholder="RRRR-MM-DD" />
                    </div>
                </div>

                <h3 className="form__sectionHeadline">Kontaktní údaje</h3>
                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <TextInput type="email" name="email" label="Váš e-mail" placeholder="Např. jan.novak@seznam.cz" />
                    </div>
                    <div className="form__group">
                        <PhoneInput name="phone" label="Telefon" />
                    </div>
                    <div className="form__group"></div>
                </div>

                <h3 className="form__sectionHeadline">Vaše bydliště</h3>
                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <TextInput name="residenceStreet" label="Ulice a č.p." placeholder="Např. Bezejmenná 123" />
                    </div>
                    <div className="form__group">
                        <TextInput name="residenceCity" label="Město" placeholder="Např. Karlovy Vary" />
                    </div>
                    <div className="form__group">
                        <TextInput name="residencePostalCode" label="PSČ" placeholder="Např. 36017" />
                    </div>
                </div>

                <h3 className="form__sectionHeadline">Vaše škola</h3>
                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <TextInput name="schoolName" label="Název školy" placeholder="Např. SZŠ a VOŠ zdravotnická Karlovy Vary" />
                    </div>
                    <div className="form__group">
                        <TextInput name="schoolAddress" label="Adresa školy" placeholder="Např. Poděbradská 1247/2, 360 01 K. Vary" />
                    </div>
                </div>
                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <TextInput name="schoolIdNumber" label="IČ školy" placeholder="Např. 01234567" />
                    </div>
                    <div className="form__group">
                        <TextInput name="schoolTaxId" label="DIČ školy" placeholder="Např. CZ01234567" />
                    </div>
                    <div className="form__group"></div>
                </div>
                <div className="form__control form__control--2c">
                    <div className="form__group">
                        <TextInput name="fieldOfStudy" label="Studovaný obor" placeholder="Např. 01234567" />
                    </div>
                    <div className="form__group">
                        <Select name="currentYearOfStudy" label="Aktuálně studovaný ročník" mapper="id=name" noFeedback={true}>
                            {arrayRange(1, 6, 1).map(hour => (
                                <option key={hour} value={hour}>{hour}</option>
                            ))}
                        </Select>
                    </div>
                </div>

                <h3 className="form__sectionHeadline">Upřesnění praxe</h3>
                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <Select name="position" label="Vaše budoucí pozice" mapper="slug=name"></Select>
                    </div>
                    <div className="form__group">
                        <Select name="location" label="Preferované pracoviště" mapper="slug=name" onChange={onLocationChange}></Select>
                    </div>
                    <div className="form__group">
                        <Select name="department" label="Preferované oddělení" mapper="id=name"></Select>
                    </div>
                </div>
                <div className="form__control form__control--3c">
                    <div className="form__group">
                        <TextInput name="startDate" label="Začátek praxe" placeholder="RRRR-MM-DD" />
                    </div>
                    <div className="form__group">
                        <TextInput name="endDate" label="Konec praxe" placeholder="RRRR-MM-DD" />
                    </div>
                    <div className="form__group"></div>
                </div>
                <div className="form__control form__control--1c">
                    <div className="form__group">
                        <TextArea name="notice" label="Chcete něco doplnit?" />
                    </div>
                </div>

                <h3 className="form__sectionHeadline">Ubytování</h3>
                <div className="form__control">
                    <Checkbox name="accommodationRequired" label="Potřebuji ubytování po dobu praxe" />
                </div>
                {form.values['accommodationRequired'] && <div className="form__control form__control--3c" style={{ marginTop: '-1rem' }}>
                    <div className="form__group">
                        <div style={{ paddingLeft: '2.25rem' }}>
                            <Radio name="customAccommodationDate" label="v termínu praxe" value="no" />
                            <Radio name="customAccommodationDate" label="v jiném termínu" value="yes" />
                        </div>
                    </div>
                    {form.values['customAccommodationDate'] === 'yes' && <>
                        <div className="form__group">
                            <TextInput name="accommodationFirstDay" label="První den ubytování" placeholder="RRRR-MM-DD" />
                        </div>
                        <div className="form__group">
                            <TextInput name="accommodationLastDay" label="Poslední den ubytování" placeholder="RRRR-MM-DD" />
                        </div>
                    </>}
                </div>}

                <div className="form__control">
                    <div className="form__group">
                        <Checkbox name="personalDataProcessingConsent"
                            label={<>Souhlasím se <a href="#" onClick={openModal}>zpracováním osobních údajů</a></>} />
                    </div>
                </div>

                <div className="form__control form__control--button">
                    <div className="form__group">
                        <button onClick={form.handleSubmit(onSubmit)} className={`button button--dark-blue ${sending ? 'button--disabled' : ''}`} disabled={sending}>
                            {sending ? 'Čekejte, odesílá se...' : 'Odeslat'}
                        </button>
                    </div>
                </div>
            </form>
        </FormContext.Provider>
    </>;
};

export default InternshipInquiry;
